<template>
  <input
      :type="'text'"
      :name="'comment'"
      :class="'form-control '+this.className"
      :placeholder="'Enter ...'"
      v-on:blur="onBlur"
      v-model="item"
      :disabled="isDisabled"
  />


  <span v-if="this.message.length>0" class="error invalid-feedback"
        style="display: inline;">{{ this.message }}</span>

  <!--

  is-valid


  <input type="tel" name="departure" class="form-control is-invalid" v-mask="'##:##'" placeholder="17:30"/>-->
  <!--  -->
</template>

<script>

import {mask} from 'vue-the-mask'
import axios from "axios";

export default {
  name: "CommentField",
  directives: {mask},
  props: ['workerId', 'date', 'data'],

  data() {
    return {
      'message': '',
      isSaved: 0,
      item: null,
      className: '',
      isDisabled: false
    }
  },

  mounted() {
    this.item = this.data
    this.className = ''
  },

  methods: {
    onBlur() {
      this.isDisabled = true
      this.updateData()
    },

    updateData() {
      let data = {
        date: this.date,
        comment: this.item,
      }

      if (this.workerId) {
        axios.post(
            '/api/workers/' + this.workerId + '/time_tracking',
            data)
            .then(() => {

                  this.isDisabled = false
                  this.className = 'is-valid'

                }
            ).catch((e) => {
          this.className = 'is-invalid'
          console.log('exception  updateData')
          console.log(e.message)
        })
      }


      //this.message = (Math.random() + 1).toString(36).substring(7)

    }
  }
}
</script>

<style scoped>

</style>
