<template>

  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">Отчёт</h1>
        </div><!-- /.col -->
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">

          </ol>
        </div><!-- /.col -->
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <!-- Main content -->
  <div class="content">
    <div class="container-fluid">
      <div class="row">

        <!-- /.col-md-6 -->
        <div class="col-lg-12">
          <div class="card card-primary card-outline">
            <div class="card-header">
              <h4 class="m-0 no-print">
                <MonthSelector
                    :key="date"
                    :date="date"
                ></MonthSelector>
               </h4>
            </div>
            <div class="card-body table-responsive ">
              <table class="table table-hover">
                <thead>
                <tr>
                  <th>Сотрудник</th>
                  <th>Переработки</th>
                  <th>Недочёт</th>
                  <th>Отсуствовал</th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(item,id) in this.workerList" v-bind:key="id">
                  <td>{{ item.name }}</td>
                  <td>
                    {{ getProcessingTime(item) }}
                  </td>
                  <td>
                    {{ getLateTime(item) }}
                  </td>
                  <td>
                    <div class="parent">
                      <div class="div1">{{ getAllAbsenteeismCount(item) }}</div>
                      <div class="div2"><div class="text-sm" v-if="getVacationCount(item)">({{ getVacationCount(item) }} отпуск )</div>

                        <div class="text-sm" v-if="getAbsenteeismCount(item)">({{ getAbsenteeismCount(item) }} прогулы )</div>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>

              </table>


            </div>
          </div>
        </div>
        <!-- /.col-md-6 -->
      </div>
      <!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content -->

</template>

<script>
import axios from "axios";
import {ref} from "vue";
import MonthSelector from "@/components/MonthSelector.vue";


export default {
  name: 'ReportMain',
  components: {MonthSelector},

  data() {
    return {
      workerList: ref([]),
      date: this.parseDate(this.$route.params.date)
    }
  },

  beforeMount() {
    this.date = this.parseDate(this.$route.params.date)

  },

  mounted() {
    this.getReport();
  },

  watch: {
    '$route.params.month':
        {
          handler: function (month) {
            this.date = this.parseDate(this.$route.params.year+'-'+month)
            this.getReport();
          },
        }
  },

  methods: {
    isValidDate(d) {
      return d instanceof Date && !isNaN(d);
    },
    parseDate(date) {
      if (this.isValidDate(new Date(date))) {
        return new Date(date)
      } else {
        return new Date()
      }
    },

    getReport() {
      let month = this.date.getMonth() + 1;
      let year = this.date.getFullYear();

      if (month < 10) {
        month = '0' + month
      }
      axios.get('/api/report/' + year + '/' + month).then(data => {
            this.workerList = data.data.data
          }
      )

    },


    getProcessingTime(item) {
      if (Object.prototype.hasOwnProperty.call(item, 'report') && Object.prototype.hasOwnProperty.call(item.report, 'processing_time')) {
        return item.report.processing_time
      }
      return null
    },
    getLateTime(item) {
      if (Object.prototype.hasOwnProperty.call(item, 'report') && Object.prototype.hasOwnProperty.call(item.report, 'late_time')) {
        return item.report.late_time
      }
      return null
    },

    getComments(item) {
      if (Object.prototype.hasOwnProperty.call(item, 'report') && Object.prototype.hasOwnProperty.call(item.report, 'comment')) {
        return item.report.comment
      }
      return null
    },

    getAllAbsenteeismCount(item) {
      if (Object.prototype.hasOwnProperty.call(item, 'report') && Object.prototype.hasOwnProperty.call(item.report, 'all_absenteeism_count')) {
        return item.report.all_absenteeism_count
      }
      return null
    },

    getAbsenteeismCount(item) {
      if (Object.prototype.hasOwnProperty.call(item, 'report') && Object.prototype.hasOwnProperty.call(item.report, 'absenteeism_count')) {
        return item.report.absenteeism_count
      }
      return null
    },

    getVacationCount(item) {
      if (Object.prototype.hasOwnProperty.call(item, 'report') && Object.prototype.hasOwnProperty.call(item.report, 'vacation_count')) {
        return item.report.vacation_count
      }
      return null
    },
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
