<template>

  <router-link :to="'/attendance/'+formatDate(prev)">&lt;
  </router-link>
<!--  <router-link :to="'/attendance/'+formatDate(date)">{{ now }}-->
<!--  </router-link>-->
  <span>{{ now }}</span>
  <router-link :to="'/attendance/'+formatDate(next)">&gt;
  </router-link>


</template>

<script>

import dateFormat from "@/Helpers/dateFormat";

export default {
  name: "DaySelector",
  props: ['date'],

  data() {
    return {
      now: null,
      prev: null,
      next: null,
    }
  },

  mounted() {
    console.log(this.now)
    this.initDates();
  },
  methods: {
    subtractDays(date, days) {
      const dateCopy = new Date(date);
      dateCopy.setDate(dateCopy.getDate() - days);
      return dateCopy;
    },

    addDays(date, days) {
      const dateCopy = new Date(date);
      dateCopy.setDate(dateCopy.getDate() + days);
      return dateCopy;
    },

    formatDate(date) {
      return dateFormat(date)
    },

    initDates(){
      this.now =  this.date.toLocaleDateString("ru", {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
      this.prev= this.subtractDays(this.date, 1)
      this.next=this.addDays(this.date, 1)
    }
  }

}
</script>

<style scoped>

</style>
