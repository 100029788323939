<template>
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">Посещаемость</h1>
        </div><!-- /.col -->
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
          </ol>
        </div><!-- /.col -->
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <!-- Main content -->
  <div class="content">
    <div class="container-fluid">
      <div class="row">

        <!-- /.col-md-6 -->
        <div class="col-lg-12">
          <div class="card card-primary card-outline">
            <div class="card-header">
              <form @submit.prevent="this.addNewWorker()" action="" method="post">
               <div class="input-group">
                <!-- /btn-group -->
                  <input type="text" name="name" class="form-control" v-model="this.name">
                  <div class="input-group-append">
                    <input type="submit" class="btn btn-success" value="Добавить нового сотрудника">
                  </div>
               </div>
                <div class="text-sm text-success" v-if="this.message.length>0">{{this.message}}</div>
              </form>
            </div>
            <div class="card-body table-responsive ">


              <table class="table table-hover text-nowrap">
                <thead>
                <tr>

                  <th>ID</th>
                  <th>Сотрудник</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,id) in this.workerList" v-bind:key="id">
                  <td>{{ item.id }}.</td>
                  <td>{{ item.name }} <span v-if="item.is_fired">(уволен)</span></td>
                  <td>
                    <a href="#" class="text-red" v-if="!item.is_fired" @click.prevent="this.markFired(item.id)"> <i
                        class="fa fa-exclamation-circle"></i> Пометить уволенным</a>
                    <a href="#" v-else @click.prevent="this.markNotFired(item.id)"> <i
                        class="fa fa-exclamation-circle"></i> Вернуть</a>
                  </td>
                </tr>
                </tbody>
              </table>

            </div>
          </div>
        </div>
        <!-- /.col-md-6 -->
      </div>
      <!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content -->
</template>
<script>
import axios from "axios";
import {ref} from "vue";

export default {
  name: 'CustomerList',
  data() {
    return {
      workerList: ref([]),
      name: '',
      message: ''
    }
  },
  methods: {
    getWorkerList() {
      axios.get('/api/workers').then(data => {
            this.workerList = data.data.data
          }
      )
    },

    markFired(id) {
      if (confirm("Действительно уволить?")) {
        axios.patch('/api/workers/' + id, {is_fired: 1}).then(() => {
          this.getWorkerList()
                  })

      }
    },

    markNotFired(id) {
      if (confirm("Действительно вернуть?")) {
        axios.patch('/api/workers/' + id, {is_fired: 0}).then(() => {
          this.getWorkerList()

        })


      }
    },

    addNewWorker(){
      if(this.name.length > 0){
        axios.post('/api/workers', {name: this.name}).then(() => {
          this.getWorkerList()
          this.message = 'Сотрудник добавлен в конец списка'
        })
      }
    }
  },
  mounted() {
    this.getWorkerList();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
