<template>

  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">Посещаемость</h1>
        </div><!-- /.col -->
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">

          </ol>
        </div><!-- /.col -->
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <!-- Main content -->
  <div class="content">
    <div class="container-fluid">
      <div class="row">

        <!-- /.col-md-6 -->
        <div class="col-lg-12">
          <div class="card card-primary card-outline">
            <div class="card-header">
              <h4 class="m-0">
                <DaySelector
                    :key="date"
                    :date="this.date"
                ></DaySelector>
              </h4>
            </div>
            <div class="card-body table-responsive ">


              <table class="table table-hover text-nowrap">
                <thead>
                <tr>
                  <th></th>
                  <th>Сотрудник</th>
                  <th>Пришёл</th>
                  <th>Ушёл</th>
                  <th>Комментарий</th>
                </tr>
                </thead>

                <tbody>

                <tr v-for="(item,id) in this.workerList" v-bind:key="id">
                  <td>
                    <AttendanceCheckbox
                        :key="getAttendance(item)"
                        :worker-id="item.id"
                        :date="formatDate(this.date)"
                        :data="getAttendance(item)"
                    ></AttendanceCheckbox>
                  </td>
                  <td>{{ item.id }}. {{ item.name }}</td>
                  <td>
                    <div class="form-group">
                      <TimeInput
                          :key="getArrivalTime(item)"
                          :inputName="'arrival'"
                          :placeHolder="'8:30'"
                          :worker-id="item.id"
                          :date="formatDate(this.date)"
                          :data="getArrivalTime(item)"
                      ></TimeInput>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <TimeInput
                          :key="getDepartureTime(item)"
                          :inputName="'departure'"
                          :placeHolder="'17:30'"
                          :worker-id="item.id"
                          :date="formatDate(this.date)"
                          :data="getDepartureTime(item)"
                      ></TimeInput>
                    </div>

                  </td>
                  <td>
                    <div class="form-group">
                      <CommentField
                          :key="getComment(item)"
                          :worker-id="item.id"
                          :date="formatDate(this.date)"
                          :data="getComment(item)"
                      ></CommentField>
                    </div>

                  </td>
                </tr>


                </tbody>

              </table>


            </div>
          </div>
        </div>
        <!-- /.col-md-6 -->
      </div>
      <!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content -->

</template>

<script>

import axios from "axios";
import {ref} from "vue";
import TimeInput from "@/components/TimeInput.vue";
import DaySelector from "@/components/DaySelector.vue";
import CommentField from "@/components/CommentField.vue";
import AttendanceCheckbox from "@/components/AttendanceCheckbox.vue";
import dateFormat from "@/Helpers/dateFormat";


export default {
  name: 'AttendanceMain',
  components: {
    DaySelector,
    AttendanceCheckbox,
    CommentField,
    'TimeInput': TimeInput
  },
  data() {
    return {
      workerList: ref([]),
      date: this.parseDate(this.$route.params.date)
    }
  },

  mounted() {
    this.getWorkerList();
    this.date = this.parseDate(this.$route.params.date)
  },

  watch: {
    '$route.params.date':
        {
          handler: function (date) {
                        this.date = this.parseDate(date)
            this.getWorkerList();
          },
          //deep: true,
          //immediate: true
        }
  },


  methods: {

    formatDate(date) {
      return dateFormat(date)
    },

    parseDate(date) {
      if (this.isValidDate(new Date(date))) {
        return new Date(date)
      } else {
        return null
      }
    },

    isValidDate(d) {
      return d instanceof Date && !isNaN(d);
    },

    getWorkerList() {
      let data = {
        'filter':
            [
              {
                'field': 'time_tracking.date',
                'value': this.formatDate(this.date),
              },
              {
                'field': 'is_fired',
                'value': 0,
              }
            ],
        'include': 'time_tracking',
        'sort': 'name'
      };
      axios.get('/api/workers', {params: data}).then(data => {
            this.workerList = data.data.data
            //console.log(this.workerList)
          }
      )
    },

    getArrivalTime(item) {
      if (/*item.hasOwnProperty('time_tracking') &&*/ item.time_tracking.length > 0) {
        return item.time_tracking[0].arrival_time
      }
      return null
    },
    getDepartureTime(item) {
      if (/*item.hasOwnProperty('time_tracking') &&*/ item.time_tracking.length > 0) {
        return item.time_tracking[0].departure_time
      }
      return null
    },

    getComment(item) {
      if (/*item.hasOwnProperty('time_tracking') &&*/ item.time_tracking.length > 0) {
        return item.time_tracking[0].comment
      }
      return null
    },

    getAttendance(item) {
      if (item.time_tracking.length === 0) {
        return true
      }

      if (/*item.hasOwnProperty('time_tracking') &&*/ item.time_tracking.length > 0 && item.time_tracking[0].attendance) {
        return true
      }
      return false
    },

  },


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
