<template>

  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="#/" class="brand-link">
      <span class="brand-text font-weight-light">AdminLTE 3</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <!-- Add icons to tde links using tde .nav-icon class
               witd font-awesome or any otder icon font library -->
          <li class="nav-item menu-open">
            <ul class="nav nav-treeview">


              <li class="nav-item" v-if="store.authenticated">
                <router-link :to="'/customers/'" class="nav-link"><i class="far fa-circle nav-icon"></i>customers
                </router-link>
              </li>

              <li class="nav-item" v-if="store.authenticated">
                <router-link :to="'/attendance/'+date" class="nav-link"><i class="far fa-circle nav-icon"></i>attendance
                </router-link>
              </li>


              <li class="nav-item" v-if="store.authenticated">
                  <router-link :to="'/report/'" class="nav-link"><i class="far fa-circle nav-icon"></i>report
                  </router-link>
              </li>

              <li class="nav-item" v-if="!store.authenticated">
                <a href="/" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>login</p>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>



  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <router-view></router-view>
  </div>




</template>


<script>

import {store} from '@/store/store.js'
import dateFormat from '@/Helpers/dateFormat'
/*const routes = {
  '/': LoginForm,
  '/customers': CustomerList,
  '/attendance': AttendanceMain,
  '/report': ReportMain,
}*/


export default {
  data() {
    return {
      store: store,
      date: '',
    }
  },

  mounted() {
    this.date = dateFormat()
  },
  methods: {
  }

}
</script>

