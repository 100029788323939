export default function dateFormat(date) {
    let objectDate;
    if (!(date === undefined)) {
        objectDate = new Date(date);
    } else {
        objectDate = new Date();
    }
    let day = objectDate.getDate();
    let month = objectDate.getMonth() + 1;
    let year = objectDate.getFullYear();

    if (month < 10) {
        month = '0' + month
    }

    if (day < 10) {
        day = '0' + day
    }

    return year + "-" + month + "-" + day;
}
