import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'




import axios from "axios"
import LoginForm from "@/components/LoginForm.vue";
import CustomerList from "@/components/CustomerList.vue";
import AttendanceMain from "@/components/AttendanceMain.vue";
import ReportMain from "@/components/ReportMain.vue";
import { store } from '@/store/store.js'


axios.defaults.baseURL = 'https://api.ooopopov.ru/'
axios.defaults.withCredentials = true
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'https://api.ooopopov.ru/';
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';


const routes = [
    { path: '/', component: LoginForm },
    { path: '/login', component: LoginForm },
    { path: '/customers', component: CustomerList },
    { path: '/attendance/:date?', component: AttendanceMain },
    { path: '/report/:year?/:month?', component: ReportMain },
]

const router = createRouter({
    history: createWebHistory(),
    routes, // short for `routes: routes`
})

router.beforeEach(async (to) => {
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);

    if (localStorage.getItem('authenticated')) {
        try {
            store.authenticated=true
        } catch(e) {
            localStorage.removeItem('cats');
        }
    }

    if (authRequired && !store.authenticated) {
        //auth.returnUrl = to.fullPath;
        return '/login';
    }
});


const app = createApp(App)

app.use(router)
app.mount('#app')
