import axios from "axios";

import {computed} from 'vue'
import {store} from '@/store/store.js'


export default function useAuth() {

    const getAuthenticated = computed(() => store.authenticated)

    const setAuthenticated = (authenticated) => {
        store.authenticated = authenticated
        localStorage.authenticated = authenticated
    }

    const updateData = async () => {
        try {
            // let response = await axios.get('/api/report/2023/6')
            setAuthenticated(true)
            // setData(response.data)

            //console.log(store)
        } catch (e) {
            setAuthenticated(false)
            console.log(e)
        }

    }
    const login = async (credentials) => {
        await axios.get('/sanctum/csrf-cookie')
        try {
            await axios.post('/login', credentials)
            await updateData();
        } catch (e) {
            //setAuthenticated(false)
            // setData({})
            console.log(e.response.data.message)
        }


    }


    return {
        login,
        getAuthenticated
    }
}
