<template>
  <input
      :type="'tel'"
      :name="this.inputName"
      :class="'form-control '+this.className"
      v-mask="'##:##'"
      :placeholder="this.placeHolder"
      v-on:blur="onBlur"
      v-model="item"
  />


  <span v-if="this.message.length>0" class="error invalid-feedback"
        style="display: inline;">{{ this.message }}</span>

  <!--

  is-valid


  <input type="tel" name="departure" class="form-control is-invalid" v-mask="'##:##'" placeholder="17:30"/>-->
  <!--  -->
</template>

<script>

import {mask} from 'vue-the-mask'
import axios from "axios";

export default {
  name: "TimeInput",
  directives: {mask},
  props: ['inputName', 'placeHolder', 'workerId', 'date', 'data'],

  data() {
    return {
      message: '',
      isSaved: 0,
      item: null,
      className: ''
    }
  },

  mounted() {
    this.item = this.data
    this.className = ''
  },

  watch: {
    'data':
        {
          handler: function (data) {
            this.item = data
            this.className= ''
          },
        }
  },

  emits: ['update_time_tracking'],


  methods: {
    onBlur() {
      this.isDisabled = true

      //todo сделать что бы обновление посылалось только если данные поменялись
      this.updateData()

    },

    updateData() {

      let data = {
        date: this.date,
      }

      if (this.inputName === 'arrival') {
        data.arrival_time = this.item
      } else if (this.inputName === 'departure') {
        data.departure_time = this.item
      }

      if (this.workerId) {
        this.message = ''
        axios.post(
            '/api/workers/' + this.workerId + '/time_tracking', data)
            .then(() => {
                  this.isDisabled = false
                  this.className = 'is-valid'
                }
            ).catch((e) => {
          this.className = 'is-invalid'
          this.message = e.message
          console.log('exception  updateData')
          console.log(e.message)
        })

      }


      //this.message = (Math.random() + 1).toString(36).substring(7)

    }
  }
}
</script>

<style scoped>

</style>
