<template>

  <router-link :to="'/report/'+getYear(prev)+'/'+getMonth(prev)">&lt;
  </router-link>
  <span>{{ now }}</span>
  <router-link :to="'/report/'+getYear(next)+'/'+getMonth(next)">&gt;
  </router-link>


</template>

<script>
export default {
  name: "MonthSelector",
  props: ['date'],

  data() {
    return {
      now: null,
      prev: null,
      next: null,
      year: '',
      month: '',
    }
  },

  beforeMount(){
    this.initDates();
  },

  mounted() {
  },
  methods: {
    subtractMonths(date, months) {
      const dateCopy = new Date(date);
      dateCopy.setMonth(dateCopy.getMonth() - months);
      return dateCopy;
    },

    addMonths(date, months) {
      const dateCopy = new Date(date);
      dateCopy.setMonth(dateCopy.getMonth() + months);
      return dateCopy;
    },

    getYear(date){
      return date.getFullYear();
    },

    getMonth(date){
      let month = date.getMonth() + 1;
      if (month < 10) {
       month = '0' + month
      }

      return month;
    },

    initDates(){
      this.now =  this.date.toLocaleDateString("ru", {
        year: 'numeric',
        month: 'long',
      })
      this.prev= this.subtractMonths(this.date, 1)
      this.next=this.addMonths(this.date, 1)
    }
  }

}
</script>

<style scoped>

</style>
